import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data() {
    return {
      backSlash: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      selectedDiscrepancyType: "",
      selectedReasons: "",
      discrepancyData: [
        {
          name: "Reasons",
          value: "Reason",
        },
        {
          name: "Resolutions",
          value: "Resolution",
        },
      ],
      reasonsData: [],
      selectedProject: "",
      showAdd: false,
      favouriteList: [],
      projectData: [],
      selectedResolutions: "",
      resolutionsData: [],
      pass: null,
      discrepancySelected: true,
      reasonListDataOnReason: [],
      resolutionListDataOnResolution: [],
      projectReasonTbl: false,
      headersReasonsTbl: [
        { text: "Active", align: "start", value: "Active", sortable: false, class: "primary customwhite--text" },
        { text: "Project", value: "Project", class: "primary customwhite--text" },
      ],
      headersResolutionsTbl: [
        { text: "Active", align: "start", value: "Active", sortable: false, class: "primary customwhite--text" },
        { text: "Project", value: "Project", class: "primary customwhite--text" },
      ],
      sortBy: "Project",
      sortDesc: false,
      projectResolutionTbl: false,
      searchProjReason: "",
      searchProjResolution: "",
      reasonProjectTbl: false,
      dropdownSelect: "",
      projectReaListData: [],
      searchProjectRea: "",
      headersProjectReaTbl: [
        { text: "Active", align: "start", value: "Active", sortable: false, class: "primary customwhite--text" },
        { text: "Reason", value: "Reason", class: "primary customwhite--text" },
      ],
      resolutionsProjectTbl: false,
      searchProjectRes: "",
      projectResListData: [],
      headersProjectResTbl: [
        { text: "Active", align: "start", value: "Active", sortable: false, class: "primary customwhite--text" },
        { text: "Resolution", value: "Resolution", class: "primary customwhite--text" },
        { text: "Purge", value: "Purge", class: "primary customwhite--text" },
        { text: "Clear", value: "Clear", class: "primary customwhite--text" },
      ],
      sortByProjRea: "Reason",
      sortByProjRes: "Resolution",
      discrepancySelection: false,
      AddNewItemDialog: false,
      newItemName: "",
      editArray: [],
      max100Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 100 || "Max Length must be 100 characters or less"],
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      NewResolution: false,
      purgeAction: false,
      clearingRes: false,
      totalRecords: 0,
      selectedOption: false,
      popupTitleParent: "",
      itemStatusDialog: false,
      selectedChange: "",
    };
  },
  mounted() {
    this.getProjectList();
  },

  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },

  methods: {
    //For the page name
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Add or remove Favourtite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },

    //Clear Search
    onSearchCancel() {
      this.selectedOption = false;
      this.selectedDiscrepancyType = "";
      this.selectedReasons = "";
      this.reasonsData = [];
      this.selectedProject = "";
      this.projectData = [];
      this.selectedResolutions = "";
      this.resolutionsData = [];
      this.discrepancySelected = true;
      this.totalRecords = 0;
      //Reason Project Table
      this.projectReasonTbl = false;
      this.searchProjReason = "";
      //Resolution Project Table
      this.projectResolutionTbl = false;
      this.searchProjResolution = "";
      this.dropdownSelect = "";
      //Project Reason Table
      this.reasonProjectTbl = false;
      this.searchProjectRea = "";
      //Project Resolution Table
      this.resolutionsProjectTbl = false;
      this.searchProjectRes = "";
      this.discrepancySelection = false;
      this.newItemName = "";
      this.editArray = [];
      this.NewResolution = false;
      this.purgeAction = false;
      this.clearingRes = false;
      this.itemStatusDialog = false;
      this.selectedChange = "";
      this.AddNewItemDialog = false;
      this.$refs.ManageDiscrepancyListForm.resetValidation();
      this.getProjectList();
    },
    //reset function main
    resetFunction() {
      this.onSearchCancel();
    },
    //Discrepancy selected
    discrepancyTypeSelect(item) {
      this.dropdownSelect = item;
      this.discrepancySelection = true;
      this.$refs.ManageDiscrepancyListForm.resetValidation();
      this.selectedResolutions = "";
      this.selectedReasons = "";
      this.selectedProject = "";
      this.projectReasonTbl = false;
      this.projectResolutionTbl = false;
      this.reasonProjectTbl = false;
      this.resolutionsProjectTbl = false;
      this.selectedOption = true;
      if (item == "Reason") {
        this.getReasonDropdownData();
      } else {
        this.getResolutionDropdownData();
      }
      this.discrepancySelected = false;
    },
    //Reason Dropdown Initial Load
    getReasonDropdownData() {
      this.pass = 1;
      this.reasonsData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/discrepancy_reasonheader?Pass=${this.pass}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.reasonsData = responseData.ReasonList;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Resolution Dropdown Initial Load
    getResolutionDropdownData() {
      this.pass = 2;
      this.resolutionsData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/discrepancy_reasonheader?Pass=${this.pass}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.resolutionsData = responseData.ResolutionList;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Projects List
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProject("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    //On Reason Select
    reasonSelect() {
      this.selectedProject = "";
      this.$refs.ManageDiscrepancyListForm.resetValidation();
      if (this.selectedReasons !== "" && this.selectedReasons !== undefined) {
        this.getReasonListOnReason(this.selectedReasons);
      }
    },
    //Get Reasons for Projects List based on the selection
    getReasonListOnReason(Reasons) {
      this.reasonListDataOnReason = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.totalRecords = 0;
      this.axios
        .get(`/cp/discrepancy_reasonlist?ReasonId=${Reasons}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.reasonListDataOnReason = responseData.Data;
            this.projectReasonTbl = true;
            this.reasonProjectTbl = false;
            this.projectResolutionTbl = false;
            this.resolutionsProjectTbl = false;
            this.totalRecords = responseData.Data.length;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Active status change for a Project in Reason table
    projectReasonActiveChange(item) {
      this.editArray = [];
      this.selectedChange = "";
      this.editArray = item;
      this.itemStatusDialog = true;
      if (item.Active ==true) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.Active == false) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
      this.selectedChange = "Project";
    },
    //On Resolution select
    resolutionSelect() {
      this.selectedProject = "";
      this.$refs.ManageDiscrepancyListForm.resetValidation();
      if (this.selectedResolutions !== "" && this.selectedResolutions !== undefined) {
        this.getResolutionListOnResolution(this.selectedResolutions);
      }
    },
    //Get Resolutions for Projects List based on the selection
    getResolutionListOnResolution(Resolution) {
      this.resolutionListDataOnResolution = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.totalRecords = 0;
      this.axios
        .get(`/cp/discrepancy_resolutionlist?ResolutionId=${Resolution}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.resolutionListDataOnResolution = responseData.Data;
            this.projectResolutionTbl = true;
            this.projectReasonTbl = false;
            this.reasonProjectTbl = false;
            this.resolutionsProjectTbl = false;
            this.totalRecords = responseData.Data.length;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Active status change for a Project in Resolution table
    projectResolutionActiveChange(item) {
      this.editArray = [];
      this.selectedChange = "";
      this.editArray = item;
      this.itemStatusDialog = true;
      if (item.Active == true) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.Active == false) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
      this.selectedChange = "Project";
    },
    //On Project select
    projectSelect() {
      this.selectedReasons = "";
      this.selectedResolutions = "";
      this.$refs.ManageDiscrepancyListForm.resetValidation();
      if (this.selectedProject !== "" && this.selectedProject !== undefined) {
        if (this.dropdownSelect == "Reason") {
          this.getProjectReasonList(this.selectedProject);
        } else {
          this.getProjectResolutionList(this.selectedProject);
        }
      }
    },
    //Get Reasons for Project List based on the selection
    getProjectReasonList(Project) {
      this.projectReaListData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.totalRecords = 0;
      this.axios
        .get(`/cp/discrepancy_reasonlist?ProjectId=${Project}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectReaListData = responseData.Data;
            this.projectReasonTbl = false;
            this.reasonProjectTbl = true;
            this.projectResolutionTbl = false;
            this.resolutionsProjectTbl = false;
            this.totalRecords = responseData.Data.length;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Active status change for a Reason in Project table
    projectReasonChange(item) {
      this.editArray = [];
      this.selectedChange = "";
      this.editArray = item;
      this.itemStatusDialog = true;
      if (item.Active == true) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.Active == false) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
      this.selectedChange = "ResRea";
    },
    //Get Resolutions for Project List based on the selection
    getProjectResolutionList(Project) {
      this.projectResListData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.totalRecords = 0;
      this.axios
        .get(`/cp/discrepancy_resolutionlist?ProjectId=${Project}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectResListData = responseData.Data;
            this.projectReasonTbl = false;
            this.reasonProjectTbl = false;
            this.projectResolutionTbl = false;
            this.resolutionsProjectTbl = true;
            this.totalRecords = responseData.Data.length;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Active status change for a Resolution in Project table
    projectResolutionChange(item) {
      this.editArray = [];
      this.selectedChange = "";
      this.editArray = item;
      this.itemStatusDialog = true;
      if (item.Active == true) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.Active == false) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
      this.selectedChange = "ResRea";
    },
    //New Reason or New Resolution Button clicked
    newDiscrepancyClicked() {
      this.newItemName = "";
      this.AddNewItemDialog = true;
      if (this.selectedDiscrepancyType == "Resolutions") {
        this.NewResolution = true;
      } else {
        this.NewResolution = false;
      }
    },
    //API call for new Reason or Resolution
    addNewReasonOrResolution() {
      if (this.selectedDiscrepancyType == "Reason") {
        this.pass = 1;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let reasonObject = {
          ListId: 0,
          ProjectId: 0,
          Active: 0,
          Pass: this.pass,
          UserId: parseInt(this.userId),
          Listitem: this.newItemName,
          Purge: 0,
          Clear: 0,
        };
        this.axios
          .post("/cp/discrepancy_reason_upsert", reasonObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.newItemName = "";
              this.AddNewItemDialog = false;
              this.getReasonDropdownData();
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.pass = 1;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let resolutionObject = {
          ListId: 0,
          ProjectId: 0,
          Active: 0,
          Pass: this.pass,
          UserId: parseInt(this.userId),
          Listitem: this.newItemName,
          Purge: this.purgeAction == true ? 1 : 0,
          Clear: this.clearingRes == true ? 1 : 0,
        };
        this.axios
          .post("/cp/discrepancy_resolution_upsert", resolutionObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.newItemName = "";
              this.AddNewItemDialog = false;
              this.getResolutionDropdownData();
              this.purgeAction = false;
              this.clearingRes = false;
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Close dialog popup
    closeAddItemDialog() {
      if (this.AddNewItemDialog) {
        this.$refs.NewForm.resetValidation();
        this.AddNewItemDialog = false;
        this.newItemName = "";
      }
    },
    //Edit existing Reason or Resolution for a Project
    editNewReasonOrResolution(edit) {
      if (this.selectedDiscrepancyType == "Reason") {
        this.pass = 2;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let reasonEditObject = {
          ListId: parseInt(edit.ReasonKey),
          ProjectId: parseInt(this.selectedProject),
          Active: !edit.Active == true ? 1 : 0,
          Pass: this.pass,
          UserId: parseInt(this.userId),
          Listitem: edit.Reason,
          Purge: 0,
          Clear: 0,
        };
        this.axios
          .post("/cp/discrepancy_reason_upsert", reasonEditObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.itemStatusDialog = false;
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.getProjectReasonListNoLoader(this.selectedProject);  
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.pass = 2;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let resolutionEditObject = {
          ListId: parseInt(edit.ResKey),
          ProjectId: parseInt(this.selectedProject),
          Active: !edit.Active == true ? 1 : 0,
          Pass: this.pass,
          UserId: parseInt(this.userId),
          Listitem: edit.Resolution,
          Purge: edit.Purge == true ? 1 : 0,
          Clear: edit.Clear == true ? 1 : 0,
        };
        this.axios
          .post("/cp/discrepancy_resolution_upsert", resolutionEditObject)
          .then((response) => {
            
            if (response.status == 200 || response.status == 204) {
              this.itemStatusDialog = false;
              if (this.resolutionsProjectTbl) this.getProjectResolutionListNoLoader(this.selectedProject);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Edit existing Reason or Resolution
    editProjReasonOrResolution(edit) {
      if (this.selectedDiscrepancyType == "Reason") {
        this.pass = 2;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let reasonEditObject = {
          ListId: parseInt(this.selectedReasons),
          ProjectId: parseInt(edit.ProjectId),
          Active: !edit.Active == true ? 1 : 0,
          Pass: this.pass,
          UserId: parseInt(this.userId),
          Listitem: edit.Project,
          Purge: 0,
          Clear: 0,
        };
        this.axios
          .post("/cp/discrepancy_reason_upsert", reasonEditObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.itemStatusDialog = false;
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
      
              this.projectReasonTbl? this.getReasonListOnReasonNoLoader(this.selectedReasons):"";
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.pass = 2;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let resolutionEditObject = {
          ListId: parseInt(this.selectedResolutions),
          ProjectId: parseInt(edit.ProjectId),
          Active: !edit.Active == true ? 1 : 0,
          Pass: this.pass,
          UserId: parseInt(this.userId),
          Listitem: edit.Project,
          Purge: 0,
          Clear: 0,
        };
        this.axios
          .post("/cp/discrepancy_resolution_upsert", resolutionEditObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.itemStatusDialog = false;
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              if (this.projectResolutionTbl) this.getResolutionListOnResolutionNoLoader(this.selectedResolutions);
              this.reasonProjectTbl? this.getProjectReasonListNoLoader(this.selectedProject):""
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Status change popup close click event
    onClickCancel() {
      this.itemStatusDialog = false;
      if (this.projectReasonTbl) this.getReasonListOnReasonNoLoader(this.selectedReasons);
      if (this.projectResolutionTbl) this.getResolutionListOnResolutionNoLoader(this.selectedResolutions);
      if (this.reasonProjectTbl) this.getProjectReasonListNoLoader(this.selectedProject);
      if (this.resolutionsProjectTbl) this.getProjectResolutionListNoLoader(this.selectedProject);
    },
    //Edit Status change for a item
    itemStatusChange() {
      if (this.selectedChange == "ResRea") {
        this.editNewReasonOrResolution(this.editArray);
      } else if (this.selectedChange == "Project") {
        this.editProjReasonOrResolution(this.editArray);
        
      }
    },
    //Making user to select any one switch while creating a new Resolution
    changePurge() {
      if (this.purgeAction == true) this.clearingRes = false;
    },
    changeClearRes() {
      if (this.clearingRes == true) this.purgeAction = false;
    },
    //Get Reasons for Projects List based on the selection without loader
    getReasonListOnReasonNoLoader(Reasons) {
      this.axios
        .get(`/cp/discrepancy_reasonlist?ReasonId=${Reasons}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.reasonListDataOnReason = responseData.Data;
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Resolutions for Projects List based on the selection without loader
    getResolutionListOnResolutionNoLoader(Resolution) {
      this.axios
        .get(`/cp/discrepancy_resolutionlist?ResolutionId=${Resolution}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.resolutionListDataOnResolution = responseData.Data;
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Reasons for Project List based on the selection without loader
    getProjectReasonListNoLoader(Project) {
      this.axios
        .get(`/cp/discrepancy_reasonlist?ProjectId=${Project}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectReaListData = responseData.Data;
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Resolutions for Project List based on the selection without loader
    getProjectResolutionListNoLoader(Project) {
      this.axios
        .get(`/cp/discrepancy_resolutionlist?ProjectId=${Project}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectResListData = responseData.Data;
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
  },
};
